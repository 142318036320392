@tailwind components;

@layer components {
  .bg-container {
    background-color: transparent;
  }
  .container-card-notification {
    @apply flex flex-col w-full gap-5;
  }

  .container-notification {
    @apply bg-white  flex flex-col w-full;
    border-radius: 0.625rem;
    padding: 1.25rem;
  }

  @media (max-width: 767.999px) {
    .container-card-notification {
      background-color: #ffffff;
      min-height: 100vh;
    }
    .container-notification {
      padding: 0;
    }
    .hidden-bar-mobile {
      padding: 0 !important;
    }
    .hidden-bar-mobile .nav-partner {
      display: none !important;
    }
    .hidden-bar-mobile .nav-breadcrumb {
      display: none !important;
    }
  }
  .border-bottom {
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  }
  .nav-top-mobile {
    display: none;
  }
  @media (max-width: 767.999px) {
    .link-icon {
      position: absolute;
      left: 0;
      top: 0.6875rem;
    }
    .nav-top-mobile {
      @apply flex justify-center w-full;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      padding: 0.625rem 0.9375rem;
      position: relative;
    }
    .nav-top-mobile span {
      @apply text-dark-gray;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .list-item-notification {
    @apply flex gap-3 justify-between w-full items-start;
    padding: 0.9375rem;
    border-bottom: 1px solid #e3e3e3;
    max-width: 25.75rem;
  }
  .list-item-notification.active {
    background: #fceceb;
  }
  .list-item-notification:last-child {
    border-bottom: 0;
  }
  .item-start {
    @apply flex gap-3;
  }
  .box-icon-noti {
    @apply flex items-center justify-center;
    position: relative;
    max-width: 2.0625rem;
    min-width: 2.0625rem;
    max-height: 2.0625rem;
    min-height: 2.0625rem;
    background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0%
      0% no-repeat padding-box;
    border-radius: 50%;
  }
  .dot-active {
    max-width: 0.625rem;
    min-width: 0.625rem;
    max-height: 0.625rem;
    min-height: 0.625rem;
    background: #ff5757 0% 0% no-repeat padding-box;
    border-radius: 50%;
    border: 2px solid #ffffff;
    position: absolute;
    top: -0.1875rem;
    right: 0;
  }

  .box-empty-noti {
    min-width: 25.75rem;
  }

  .box-detail {
    @apply flex flex-col gap-3;
    line-height: 1.25rem !important;
  }

  .text-header-noti {
    color: #333333 !important;
    font-size: 1.125rem !important;
    line-height: 1.125rem !important;
    font-weight: bold !important;
  }

  .text-message-noti {
    color: #333333 !important;
    font-size: 1.125rem !important;
    line-height: 1.125rem !important;
  }

  .text-date-noti {
    @apply text-gray-9a;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  @media (max-width: 767.999px) {
    .list-item-notification {
      padding: 0.9375rem;
    }
  }
  .padding-btn {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .dropdown-menu-content {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .box-total {
    @apply z-20 text-dark-gray bg-white absolute flex items-center justify-center rounded-full;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    right: -0.25rem;
    top: -0.125rem;
    z-index: 20;
    min-width: 0.875rem;
    min-height: 14px;
    padding: 0 3px;
    border: 0.0625rem solid #ffffff;
  }
}

@layer components {
  .height-nav {
    height: 8.8031rem;
  }
  @media (min-width: 1024px) {
    .height-nav {
      height: 8.265rem;
    }
  }
  .nav-container {
    @apply w-full;
  }
  .nav-lang {
    @apply bg-red-750 py-1 w-full flex;
  }
  .text-nav-lang {
    @apply text-white;
    font-family: var(--DBHeavent);
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .text-btn-lang {
    /* @apply inline-flex w-full items-center justify-center text-white; */
    font-family: var(--DBHeavent);
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #ffffff;
  }
  .item-menu-nav {
    @apply px-3 py-2 flex justify-center items-center;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-family: var(--DBHeavent) !important;
    font-weight: 300;
    min-width: max-content;
  }
  .item-menu-nav-hover {
    @apply text-white  hover:text-yellow-200;
  }

  .dropdown-absolute {
    @apply absolute left-0 top-0;
  }
  .relative-link {
    @apply flex;
    padding-left: 7.7556rem;
  }
}

.container-nav-overflow {
  @apply flex overflow-x-scroll overflow-y-hidden whitespace-nowrap space-x-4 w-full justify-center;
}

.box-logo {
  @apply hidden;
}
@media (min-width: 1024px) {
  .box-logo {
    @apply flex;
    width: 12.1875rem;
  }
}
.box-logo-mobile {
  @apply flex;
  width: 2.5625rem;
}
@media (min-width: 1024px) {
  .box-logo-mobile {
    @apply hidden;
  }
}
.p-15px {
  padding: 0.9375rem;
}
/* .custom-input-search {
  @apply w-full;
}
.custom-input-search .ant-input {
  border-color: #ffffff !important;
  padding: 0.5rem 3rem 0.5rem 1.125rem !important;
} */
@media (min-width: 640px) {
  .custom-input-search .ant-input {
    padding: 0.25rem 3.125rem 0.25rem 1.125rem !important;
  }
}
.ant-input {
  @apply text-dark-gray;
  font-family: var(--DBHeavent) !important;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0.5rem 1.125rem;
}
.btn-search {
  @apply flex items-center absolute;
  right: 0.1875rem;
  top: 0.1875rem;
  height: 2.125rem;
  padding: 0 0.75rem !important;
}
@media (min-width: 640px) {
  .btn-search {
    padding: 0 1rem !important;
    height: 1.625rem;
  }
}

@media (max-width: 639.88888px) {
  .w-41px {
    width: 2.5625rem;
  }
}

@media (max-width: 639.88888px) {
  .w-90px {
    width: 5.625rem;
  }
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

.container {
  padding: 0 1rem;
}
.gap-lang {
  gap: 3px !important;
}
.custom-dropdown-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-register {
  @apply flex flex-row items-center text-white hover:text-gray-100;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.1875rem 0.9375rem;
  border-right: 1px solid #ffffff;
}
.link-login {
  @apply flex flex-row items-center text-white hover:text-gray-100;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.1875rem 0.9375rem;
  width: 100%;
  min-width: max-content;
}

.line-register-right {
  height: 1rem;
  min-width: 1px;
  max-width: 1px;
  background-color: #ffffff;
}

@media (max-width: 1023.999px) {
  .line-register-right {
    display: none;
  }
}
.link-nav-verify {
  @apply flex flex-col sm:flex-row items-center text-white hover:text-gray-100 justify-center sm:justify-end gap-1 sm:gap-0;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.1875rem 0.1875rem;
  width: 100%;
}
@media (min-width: 640px) {
  .link-nav-verify {
    padding: 0.1875rem 0.9375rem;
  }
}
.icon-login {
  @apply pb-3;
  transform: rotate(-90deg);
}
.warp-dropdown-mb {
  @apply hidden;
}
.wrap-user-is-login {
  @apply flex flex-row items-center bg-white;
  max-height: 2rem;
  min-height: 2rem;
  border-radius: 1.875rem;
}
.user-is-login {
  @apply cursor-pointer flex flex-row justify-center items-center;
  max-width: 1.625rem;
  min-width: 1.625rem;
  max-height: 1.625rem;
  min-height: 1.625rem;
  margin-left: 0.1875rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: transparent linear-gradient(180deg, #ff0000 0%, #cd0000 100%) 0%
    0% no-repeat padding-box;
}
.text-is-login {
  @apply cursor-pointer text-dark-gray px-2 inline-block;
  font-size: 1.125rem;
  line-height: 1.125rem;
  max-width: 4.0556rem;
}
.user-login-mb {
  @apply cursor-pointer flex flex-row justify-center items-center;
  max-width: 2.0625rem;
  min-width: 2.0625rem;
  max-height: 2.0625rem;
  min-height: 2.0625rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #ffffff;
}
.link-dropdown-mb {
  @apply flex flex-row items-center;
}
.link-dropdown-mb:hover {
  @apply text-primary;
}
.link-dropdown-mb .text-link {
  @apply flex items-center text-dark-gray hover:text-primary;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.link-dropdown-mb .text-logout {
  @apply flex items-center;
  @apply text-primary;
  font-size: 1.125rem;
  line-height: 1.125rem;
}

@media (max-width: 1023.999px) {
  .link-login {
    @apply hidden;
  }
  .link-register {
    @apply hidden;
  }
  .warp-dropdown-mb {
    @apply flex;
  }
}
@media (max-width: 639.999px) {
  .text-is-login {
    @apply hidden;
  }
  .wrap-user-is-login {
    @apply cursor-pointer;
    max-width: 2.5244rem;
    min-width: 2.5244rem;
    max-height: 2.5244rem;
    min-height: 2.5244rem;
    border-radius: 50%;
    justify-content: center;
  }
  .user-is-login {
    max-width: 2.25rem;
    min-width: 2.25rem;
    max-height: 2.25rem;
    min-height: 2.25rem;
    margin-left: 0;
  }
  .user-login-mb {
    max-width: 2.5244rem;
    min-width: 2.5244rem;
    max-height: 2.5244rem;
    min-height: 2.5244rem;
  }
}

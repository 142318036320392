@tailwind components;

@layer components {
  .btn {
    @apply bg-gray-400 rounded-full;
    font-family: var(--DBHeavent);
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.375rem;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background-size: 300% 100%;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .btn-sm {
    @apply bg-gray-400 rounded-full;
    font-family: var(--DBHeavent);
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.125rem;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background-size: 300% 100%;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  @media (min-width: 640px) {
    .btn-sm {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  .btn-sm:hover {
    background-position: 100% 0%;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .btn-sm:focus {
    outline: none;
  }
  .btn-sm:disabled {
    opacity: 0.5;
  }
  .btn:hover {
    background-position: 100% 0%;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .btn:focus {
    outline: none;
  }
  .btn:disabled {
    opacity: 0.5;
  }
  .btn-primary {
    color: #ffffff !important;
    background-image: linear-gradient(
      to right,
      var(--gradient-primary-1),
      var(--gradient-primary-2),
      var(--gradient-primary-1),
      var(--gradient-primary-2)
    ) !important;
    box-shadow: 0px 0px 10px #d200006e !important;
  }
  .btn-secondary {
    color: #ffffff !important;
    background-color: #171717 !important;
  }
  .btn-secondary:hover {
    background-color: #171717;
    box-shadow: 0px 0px 10px #17171773;
  }
  .btn-cart {
    @apply relative bg-white rounded-full flex items-center justify-center text-dark-gray;
    width: auto;
    font-family: var(--DBHeavent);
    font-weight: 400;
    font-size: 1.125rem;
    padding: 0.1875rem;
  }
  @media (min-width: 640px) {
    .btn-cart {
      min-width: 2rem;
      max-width: 2rem;
      min-height: 2rem;
      max-height: 2rem;
    }
  }
  .padding-text {
    @apply hidden;
  }
  @media (min-width: 640px) {
    .padding-text {
      @apply inline-block;
      padding-left: 0.875rem;
    }
  }
  .box-icon-cart {
    @apply z-10 relative flex items-center justify-center rounded-full;
    left: auto;
    max-width: 2.15rem;
    min-width: 2.15rem;
    max-height: 2.15rem;
    min-height: 2.15rem;
    background: transparent linear-gradient(180deg, #ff0000 0%, #cd0000 100%) 0%
      0% no-repeat padding-box;
  }
  .cm-icon-cart {
    width: 1.125rem;
    height: 1.125rem;
  }
  @media (min-width: 640px) {
    .cm-icon-cart {
      width: 1rem;
      height: 1rem;
    }
  }
  @media (min-width: 640px) {
    .box-icon-cart {
      @apply absolute;
      left: 0.1875rem;
      max-width: 1.625rem;
      min-width: 1.625rem;
      max-height: 1.625rem;
      min-height: 1.625rem;
      background: transparent
        linear-gradient(
          180deg,
          var(--gradient-secondary-1) 0%,
          var(--gradient-secondary-2) 100%
        )
        0% 0% no-repeat padding-box;
    }
  }
  .noti-cart {
    @apply z-20 text-white bg-dark-gray absolute flex items-center justify-center rounded-full;
    font-family: var(--DBHeavent);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.875rem;
    right: 0;
    top: -0.3125rem;
    z-index: 20;
    min-width: 1rem;
    min-height: 1rem;
    padding: 0 3px;
    border: 0.0625rem solid #ffffff;
  }
}

@tailwind components;

@layer components {
  .text-title-drawer {
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-family: var(--DBHeavent) !important;
    font-weight: 400;
  }
  .custom-drawer .ant-drawer-body {
    padding: 0 !important;
  }
  .custom-drawer .ant-drawer-close svg {
    fill: #ffffff;
  }
  .custom-drawer {
    border-radius: 0.625rem 0 0 0.625rem;
  }
  .custom-drawer .ant-drawer-wrapper-body {
    border-radius: 0.625rem 0 0 0.625rem;
  }
  .custom-drawer .ant-drawer-header {
    @apply bg-dark-gray;
    border-radius: 0.625rem 0 0 0;
  }
  .custom-drawer .link-menu {
    @apply flex items-center justify-between transition duration-150 ease-out;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    padding: 0.4375rem 1.25rem;
  }
  .custom-drawer .link-menu:hover {
    @apply bg-primary ease-in;
  }
  .custom-drawer .link-menu:hover .text-menu {
    @apply text-white ease-in;
  }
  .custom-drawer .link-menu .text-menu {
    @apply text-dark-gray;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-family: var(--DBHeavent);
    font-weight: 400;
  }
}
.link-out-side {
  width: 8.125rem;
}
@media (min-width: 1024px) {
  .link-out-side {
    width: 15.875rem;
  }
}
.link-out-side img {
  width: 100%;
}
.menu-category-mobile {
  display: inline-block;
}
@media (min-width: 1024px) {
  .menu-category-mobile {
    display: none;
  }
}
.mb-category {
  height: 2.2706rem;
  width: 2.2706rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  background-color: #fff !important;
  color: #707070 !important;
  padding: 0 !important;
}
@media (min-width: 1024px) {
  .mb-category {
    height: 3.1444rem;
    width: 3.1444rem;
  }
}
.mb-category:focus {
  color: #707070 !important;
  border: 1px solid #707070 !important;
  background-color: #fff !important;
}
.mb-category:hover {
  color: #707070 !important;
  border: 1px solid #707070 !important;
  background-color: #fff !important;
}

@tailwind components;

@layer components {
  .wrap-slide-recommend {
    @apply flex w-full;
  }
  .wrap-slide-recommend .swiper-wrapper {
    padding: 1rem 0 0 0;
  }
  .card-recommend {
    padding: 5px;
  }
  @media (min-width: 640px) {
    .wrap-slide-recommend .swiper-wrapper {
      padding: 1rem 0;
    }
  }
}

@tailwind components;

@layer components {
  .nav-footer {
    @apply flex flex-col gap-3;
    margin: 0 !important;
    padding: 0 !important;
  }
  .nav-footer li.header {
    color: #ffffff;
    font-weight: 500;
  }
  .nav-footer li {
    @apply text-gray-9a;
    list-style: none !important;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 400;
  }
  .nav-footer li:hover {
    color: #ffffff;
  }
  .list-socaial {
    @apply flex flex-row gap-2;
  }
  .list-socaial .box-socaial {
    @apply flex flex-row justify-center;
    min-width: 1.6875rem;
    max-width: 1.6875rem;
    min-height: 1.6875rem;
    max-height: 1.6875rem;
    border-radius: 0.5rem;
    background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0%
      0% no-repeat padding-box;
    color: #ffffff;
  }
  .box-socaial img {
    width: 1rem;
    fill: #ffffff;
  }
  .box-socaial img.facebook {
    width: 0.625rem;
  }
  .box-qr {
    @apply flex items-center justify-center;
    /* max-width: 3.1875rem;
    min-width: 3.1875rem;
    max-height: 3.1875rem;
    min-height: 3.1875rem; */
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0.3125rem;
  }
  .space-bottom {
    padding-bottom: 5rem;
  }
  @media (min-width: 640px) {
    .space-bottom {
      padding-bottom: 0;
    }
  }
  .wrap-coppy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: #1e232a;
    padding: 12px;
    color: #ffffff;
    font-size: 18px;
  }
}

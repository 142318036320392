@tailwind components;

@layer components {
  .wrap-card {
    @apply flex flex-col w-full bg-white;
    position: relative;
    border-radius: 0.625rem;
    padding: 0;
    cursor: pointer;
    @apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300;
  }
  .wrap-card:hover {
    box-shadow: 0px 0px 15px #00000033;
    padding: 0.3125rem;
  }

  .tag-brand {
    @apply text-white;
    position: absolute;
    bottom: -0.6875rem;
    left: 0.625rem;
    background: transparent linear-gradient(90deg, #ea0000 0%, #750000 100%) 0%
      0% no-repeat padding-box;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.125rem;
    padding: 0.125rem 0.5625rem;
    border-radius: 1.875rem;
  }

  .card-content {
    @apply flex flex-col;
    padding: 1.3125rem 0.625rem 0.625rem 0.625rem;
  }
  .card-content h3.title {
    @apply flex text-gray-30;
    font-size: 1.375rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin-bottom: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .card-content .price {
    @apply text-primary;
    font-size: 1.625rem;
    line-height: 1.625rem;
    font-weight: 400;
    padding-top: 0.3125rem;
  }
  .card-content .price-thb {
    color: #d200006e;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 0.3125rem;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
  }
  .card-content .price-cny {
    color: #00000029;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 0.3125rem;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
  }
  @media screen and (min-width: 640px) {
    .card-content .price {
      font-size: 1.9375rem;
      line-height: 1.9375rem;
    }
  }
  /* .card-content .price::before {
    content: '฿';
    padding-right: 0.3125rem;
  }
  .card-content .price-thb::before {
    content: '฿';
    padding-right: 0.3125rem;
  } */
  .card-content .original-price {
    @apply text-gray-9a;
    font-size: 1.375rem;
    line-height: 1.375rem;
    font-weight: 300;
    padding-top: 0.1875rem;
  }
  @media screen and (min-width: 640px) {
    .card-content .original-price {
      font-size: 1.5625rem;
      line-height: 1.5625rem;
    }
  }
  /* .card-content .original-price::before {
    content: '¥';
    padding-right: 0.3125rem;
  }
  .card-content .price-cny::before {
    content: '¥';
    padding-right: 0.3125rem;
  } */
  @media (max-width: 1023.999px) {
  }
}
.card-padding {
  padding: 0.3125rem;
}

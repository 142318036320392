@tailwind components;

@layer components {
  @media (max-width: 767.9999px) {
    .hidden-mobile .container-space-top {
      padding-top: 0 !important;
    }
    .hidden-mobile .height-nav {
      display: none;
    }
    .hidden-mobile .absolute-right-bar {
      display: none;
    }
    .hidden-mobile .absolute-right-bottom-bar {
      display: none;
    }
  }

  .container-space-top {
    padding-top: 8.8031rem;
    padding-bottom: 1.5625rem;
    min-height: 90dvh;
  }
  @media (min-width: 1024px) {
    .container-space-top {
      padding-top: 8.265rem;
    }
  }

  /* @media (min-width: 1024px) {
    .container-space-top {
      padding-top: 8.1875rem;
    }
  }
  @media (max-width: 1023.9999px) {
    .container-space-top {
      padding-top: 8.75rem;
    }
  } */
  @media (max-width: 639.999px) {
    .container-space-top {
      padding-top: 9.25rem;
      padding-bottom: 6.25rem;
    }
  }
  .absolute-right-bar {
    top: auto;
    right: auto;
    bottom: 0;
    transform: none;
  }
  @media (min-width: 640px) {
    .absolute-right-bar {
      top: 50%;
      right: 0%;
      bottom: auto;
      transform: translate(0%, -50%);
    }
  }
  .absolute-right-bottom-bar {
    display: none;
  }
  @media (min-width: 640px) {
    .absolute-right-bottom-bar {
      display: block;
      bottom: 0;
      right: 0;
    }
  }
}
.box-chat {
  @apply flex items-center;
  padding: 0.625rem 1.25rem;
  background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px 0px 0px 0px;
  opacity: 1;
}
.box-chat img {
  width: 1.5625rem;
}
.text-chat {
  font-size: 27px;
  line-height: 1.6875rem;
  font-weight: 300;
  color: #ffffff;
}
.warp-nav-right-hidden {
  @apply hidden sm:flex sm:flex-col flex-row bg-white;
  box-shadow: 0 0 0.9375rem #00000029;
  border-radius: 0.625rem 0 0 0.625rem;
}
.warp-nav-right-hidden .link-right-menu {
  @apply flex flex-col items-center justify-center;
  padding: 0.625rem;
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;
}

.warp-nav-right {
  @apply flex sm:flex-col flex-row bg-white;
  box-shadow: 0 0 0.9375rem #00000029;
  border-radius: 0.625rem 0 0 0.625rem;
}
.warp-nav-right .link-right-menu {
  @apply flex flex-col items-center justify-center;
  padding: 0.625rem 0.3125rem;
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
}
.dot-active-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1.0625rem;
  min-width: 1.0625rem;
  max-height: 1.0625rem;
  min-height: 1.0625rem;
  background: #333333;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  top: 0.3125rem;
  right: 0.9375rem;
  color: #ffffff;
  font-size: 0.625rem;
}
@media (max-width: 639.999px) {
  .dot-active-nav {
    right: 1.25rem;
    top: 0.3125rem;
  }
}
.link-right-menu:first-child {
  border-radius: 10px 0 0 0;
  border-bottom: 0;
}
.link-right-menu:last-child {
  border-radius: 0 0 0 0.625rem;
  border-bottom: 0;
}
.right-active {
  background: transparent
    linear-gradient(
      180deg,
      var(--gradient-primary-1) 0%,
      var(--gradient-primary-2) 100%
    )
    0% 0% no-repeat padding-box !important;
}
.right-active .text-right-menu {
  @apply text-white;
}
.text-right-menu {
  @apply text-gray-30;
  font-size: 1rem;
  line-height: 1rem;
  font-family: var(--DBHeavent);
  font-weight: 400;
}

@media (max-width: 639.999px) {
  .warp-nav-right {
    width: 100%;
  }
  .warp-nav-right .link-right-menu {
    border-bottom: 0;
    width: 25%;
  }
  .link-right-menu:last-child {
    border-radius: 0;
  }
  .link-right-menu:first-child {
    border-radius: 0;
  }
}

.go-to-top-btn {
  position: fixed;
  z-index: 100;
  bottom: 135px;
  right: 25px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgb(255, 78, 111), rgb(251, 145, 104));
  box-shadow: rgba(255, 78, 111, 0.5) 0px 2px 16px;
  font-size: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  transform-origin: center;
}

.go-to-top-btn:hover {
  transform: scale(1.1);
}

.line-btn {
  position: fixed;
  z-index: 100;
  bottom: 60px;
  right: 25px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  box-shadow: rgb(255, 255, 255) 0px 2px 16px;
  cursor: pointer;
  transition: transform 0.2s ease;
  transform-origin: center;
}

.line-btn:hover {
  transform: scale(1.1);
}

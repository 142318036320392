@tailwind components;

@layer components {
  .cm-tab-home {
    @apply w-full mt-4 sm:mt-8;
  }
  .cm-tab-home .ant-tabs-nav {
    margin: 0;
  }
  .cm-tab-home .ant-tabs-nav-list {
    @apply bg-white;
    border-radius: 1.875rem;
  }
  .cm-tab-home .ant-tabs-tab {
    @apply flex flex-row items-center justify-center;
    padding: 0.3125rem 1.25rem;
    border-radius: 1.875rem;
    margin: 0 !important;
    min-width: 131px;
  }
  .cm-tab-home .ant-tabs-tab-btn {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .cm-text-tab {
    @apply flex flex-row items-center justify-center gap-2;
  }
  .cm-tab-home .ant-tabs-tab .cm-text-tab {
    @apply text-dark-gray;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .cm-tab-home .ant-tabs-tab.ant-tabs-tab-active .cm-text-tab {
    color: #ffffff !important;
  }
  .cm-tab-home .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 0 !important;
    background: transparent linear-gradient(180deg, #ea0000 0%, #750000 100%) 0%
      0% no-repeat padding-box !important;
  }

  .cm-tab-home .ant-tabs-ink-bar {
    background-color: transparent;
  }

  @media (min-width: 640px) {
  }
}
.min-height-tab-component {
  padding: 2.5rem 0;
}

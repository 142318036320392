@tailwind components;

@layer components {
  .banner-first-child {
    min-width: 14.375rem;
    max-width: 14.375rem;
  }
  /* @media (min-width: 1280px) {
    .banner-first-child {
      min-width: 14.375rem;
      max-width: 14.375rem;
    }
  }
  @media screen and (min-width: 1536px) {
    .banner-first-child {
      min-width: 18.75rem;
      max-width: 18.75rem;
    }
  } */
  .wrap-slide {
    @apply pl-0 sm:pl-4;
    width: 100%;
    /* min-width: calc(100% - 14.375rem);
    max-width: calc(100% - 14.375rem); */
  }
  /* @media (min-width: 1280px) {
    .wrap-slide {
      min-width: calc(100% - 14.375rem);
      max-width: calc(100% - 14.375rem);
    }
  } */
  @media (max-width: 639.99999px) {
    .wrap-slide {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
.swiper-container {
  display: flex;
  max-width: 100%;
}
.swiper-container .swiper-wrapper {
  padding: 1rem 0 0 0;
}

@media screen and (min-width: 640px) {
  /* .swiper-container {
    max-width: calc(40rem - 17.375rem);
  } */
  .swiper-container .swiper-wrapper {
    padding: 1rem 0;
  }
}
@media screen and (min-width: 768px) {
  /* .swiper-container {
    max-width: calc(48rem - 17.375rem);
  } */
}

@media screen and (min-width: 1024px) {
  /* .swiper-container {
    max-width: calc(64rem - 17.375rem);
  } */
}

@media screen and (min-width: 1280px) {
  /* .swiper-container {
    max-width: calc(80rem - 17.375rem);
  } */
}
@media screen and (min-width: 1536px) {
  /* .swiper-container {
    max-width: calc(96rem - 17.375rem);
  } */
}
.custom-shadow:hover {
  box-shadow: 0px 0px 14px #5500004d !important;
}
@media screen and (max-width: 639.999px) {
  .swiper-container .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
    pointer-events: none !important;
  }
}
.cantainer-slide {
  @apply flex w-full py-4 bg-white;
  border-radius: 0.625rem;
  padding-left: calc(1rem - 0.3125rem);
  padding-right: 1rem;
}
.cantainer-slide img {
  object-fit: contain;
}
@media screen and (min-width: 640px) {
  .cantainer-slide {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

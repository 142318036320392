.slide-banner {
  height: 27rem;
}
.box-banner {
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}

@media (max-width: 639.9999px) {
  .slide-banner {
    height: 11.25rem;
  }
}

.image-banner-mobile {
  display: block;
}
@media (min-width: 1024px) {
  .image-banner-mobile {
    display: none;
  }
}
.slide-banner .box-inner {
  @apply z-40;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.slide-banner .swiper-pagination {
  @apply z-40;
}
.slide-banner .swiper-pagination-bullet {
  width: 2rem !important;
  height: 0.375rem !important;
  border-radius: 0 !important;
  background: rgba(255, 255, 255, 1) !important;
  opacity: 0.6 !important;
}

.slide-banner .swiper-pagination-bullet-active {
  @apply z-20;
  background: rgba(255, 0, 0, 1) !important;
  opacity: 1 !important;
}
.absolute-center {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.box-banner-menu {
  display: none;
}
@media (min-width: 1024px) {
  .box-banner-menu {
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    width: 13.75rem;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.box-banner-menu .heder-menu {
  @apply bg-dark-gray flex items-center;
  width: 100%;
  padding: 0.4375rem 1.25rem;
  border-radius: 0.625rem 0.625rem 0 0;
}
.box-banner-menu .text-header {
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-family: var(--DBHeavent);
  font-weight: 400;
}
.box-banner-menu .link-banner-menu {
  @apply flex items-center justify-between transition duration-150 ease-out;
  /* background-color: rgba(255, 255, 255, 1); */
  width: 100%;
  padding: 0.4375rem 1.25rem;
}
.wrap-box-menu {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.4375rem 0;
  border-radius: 0 0 0.625rem 0.625rem;
  min-height: 25rem;
}
/* .box-banner-menu .link-banner-menu:last-child {
} */
.link-banner-menu:hover {
  @apply bg-primary ease-in;
}
.link-banner-menu:hover .text-link-menu {
  @apply text-white ease-in;
}
.link-banner-menu .text-link-menu {
  @apply text-dark-gray;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-family: var(--DBHeavent);
  font-weight: 400;
}
